import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import NetworkCompany from '../../assets/images/clients/network-company.svg';
import YEnergy from '../../assets/images/clients/yEnergy.svg';
import Gorelectroset from '../../assets/images/clients/gorelektroset.svg';
import './clients.css';
import './@clients.css';

function Clients() {
    const { t } = useTranslation();

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    return(
        <div className='clients' id='clients'>
            <div className='clients-container'>
                <h2>{t('Наши клиенты')}</h2>
                <div className='clients-container__content'>
                    <img src={NetworkCompany} alt='' className='clients-container__content img1'/>
                    <img src={YEnergy} alt='' className='clients-container__content img2'/>
                    <img src={Gorelectroset} alt='' className='clients-container__content img3'/>
                </div>
            </div>
        </div>
    )
}

export default Clients;
