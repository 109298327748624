import React, { useEffect, useRef, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import './interaction.css';
import './@interaction.css';

function Interaction() {
    const { t } = useTranslation();
    const items = useRef([]);
    const [scrollingDown, setScrollingDown] = useState(false);

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    useEffect(() => {
        const interactionDiv = document.getElementById('interaction');

        function handleScroll() {
            const interactionRect = interactionDiv.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const scrollY = window.scrollY;
            const isScrollingDown = scrollY > interactionDiv.dataset.scrollY;

            items.current.forEach((item, index) => {
                const itemRect = item.getBoundingClientRect();
                const isLeft = index % 2 === 0; // Alternating sides for animation
                const isVisible =
                    interactionRect.top < windowHeight && interactionRect.bottom >= 0;

                if (isVisible) {
                    item.classList.add('active');
                    item.classList.remove(isLeft ? 'right-slide-in' : 'left-slide-in');
                } else {
                    item.classList.remove('active');
                    item.classList.add(isLeft ? 'left-slide-in' : 'right-slide-in');
                }

                if (isVisible && isScrollingDown) {
                    item.classList.add('scroll-down-animation');
                } else {
                    item.classList.remove('scroll-down-animation');
                }
            });

            interactionDiv.dataset.scrollY = scrollY.toString();
            setScrollingDown(isScrollingDown);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="interaction" id="interaction">
            <div className="interaction-container">
                <div className="interaction-container__title">
                    <h2>
                        {t('API функции')} <span>{t('Взаимодействия с внешними системами')}</span>
                    </h2>
                </div>
                <div className="interaction-container__content">
                    <div ref={(el) => (items.current[0] = el)} className="interaction-container__content-item left-slide-in">
                        <p>
                            {t('Разрабатывайте собственные приложения и интегрируйте ' + 'свои биллинговые системы с «Умная энергия»')}
                        </p>
                    </div>
                    <div ref={(el) => (items.current[1] = el)} className="interaction-container__content-item right-slide-in">
                        <p>
                            {t('Широкая библиотека API-функций позволяет получать ' + 'автоматически в online-режиме любые данные')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Interaction;
