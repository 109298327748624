import Header from "./components/header/Header";
import About from "./components/about/About";
import Possibilities from "./components/possibilities/Possibilities";
import Clients from "./components/clients/Clients";
import Interaction from "./components/interaction/Interaction";
import Callback from "./components/callback/Callback";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
        <Header/>
        <About/>
        <Possibilities/>
        <Clients/>
        <Interaction/>
        <Callback/>
        <Footer/>
    </>
  );
}

export default App;
