import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import Close from "../../assets/images/close.svg";
import RU from "../../assets/images/flags/RU.svg";
import EN from "../../assets/images/flags/EN.svg";
import KZ from "../../assets/images/flags/KZ.svg";
import "./CustomOffcanvas.css";

function CustomOffcanvas({ show, onHide, currentLanguage, changeLanguage }) {
    const { t } = useTranslation();

    const [isLanguageListOpen, setIsLanguageListOpen] = useState(false);

    const languageFlags = {
        ru: RU,
        en: EN,
        kz: KZ,
    };

    const availableLanguages = Object.keys(languageFlags);
    const languageList = availableLanguages.filter((lang) => lang !== currentLanguage);

    const toggleLanguageList = () => {
        setIsLanguageListOpen(!isLanguageListOpen);
    };

    const offcanvasRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
                onHide(); // Закрываем offcanvas при клике вне него
            }
        }

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onHide]);

    return (
        <div className={`custom-offcanvas ${show ? "show" : ""}`} ref={offcanvasRef}>
            <div className="custom-offcanvas-content">
                <div className="custom-offcanvas-dropdown">
                    <div className="custom-offcanvas-dropdown-content">
                        <div
                            onClick={toggleLanguageList}
                            className="custom-offcanvas-dropdown-content__current"
                        >
                            {currentLanguage.toUpperCase()}
                            <img src={languageFlags[currentLanguage]} alt={currentLanguage} />
                        </div>
                        {isLanguageListOpen && (
                            <div className="custom-offcanvas-dropdown-content__dropdown">
                                {languageList.map((lang) => (
                                    <div className="custom-offcanvas-dropdown-content__dropdown-content" key={lang}>
                                        <div onClick={() => changeLanguage(lang)}>
                                            {lang.toUpperCase()}
                                            <img src={languageFlags[lang]} alt={lang} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <button onClick={onHide}><img src={Close} alt='Close'/></button>
                </div>
                <div className="custom-offcanvas-link">
                    <a onClick={onHide} href="#possibilities">{t("Возможности системы")}</a>
                    <a onClick={onHide} href="#clients">{t("Наши клиенты")}</a>
                    <a onClick={onHide} href="#form">
                        <span>{t("Оставить заявку на тестирование")}</span>
                    </a>
                </div>
            </div>
            <div className="custom-offcanvas-area">
                <a href="http://app.smart-energy.online">
                    <span>{t("Личный кабинет")}</span>
                </a>
            </div>
        </div>
    );
}

export default CustomOffcanvas;
