import React from 'react';
import './CustomCheckbox.css';
import {useTranslation} from "react-i18next";

function CustomCheckbox({ isChecked, onChange }) {
    const { t } = useTranslation();

    return (
        <label className="custom-checkbox">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                required
            />
            <span className="checkmark"></span>
            <label htmlFor="agreementCheckbox">
                {t('Нажимая кнопку «Отправить заявку» вы соглашаетесь с ')}
                <a href="/privacy" target='_blank'>{t('Политикой')}
                    {t(' конфиденциальности')}</a>
                <span>{t(' сайта')}</span>
            </label>
        </label>
    );
}

export default CustomCheckbox;
