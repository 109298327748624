import React, {useEffect, useRef, useState} from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import './possibilities.css';
import './@posibilities.css';

function Possibilities() {
    const { t } = useTranslation();
    const items = useRef([]);
    const [scrollingDown, setScrollingDown] = useState(false);

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    useEffect(() => {
        const possibilitiesDiv = document.getElementById('possibilities');
        const possibilitiesContent = document.querySelector('.possibilities-container__content');

        function handleScroll() {
            const possibilitiesRect = possibilitiesDiv.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const scrollY = window.scrollY;
            const isScrollingDown = scrollY > possibilitiesDiv.dataset.scrollY;

            items.current.forEach((item, index) => {
                const itemRect = item.getBoundingClientRect();
                const isLeft = index < 3;
                const isVisible =
                    possibilitiesRect.top < windowHeight && possibilitiesRect.bottom >= 0;

                if (isVisible) {
                    item.classList.add('active');
                    item.classList.remove(isLeft ? 'right-slide-in' : 'left-slide-in');
                } else {
                    item.classList.remove('active');
                    item.classList.add(isLeft ? 'left-slide-in' : 'right-slide-in');
                }

                if (isVisible && isScrollingDown) {
                    item.classList.add('scroll-down-animation');
                } else {
                    item.classList.remove('scroll-down-animation');
                }
            });

            possibilitiesDiv.dataset.scrollY = scrollY.toString();
            setScrollingDown(isScrollingDown);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='possibilities' id='possibilities'>
            <div className='possibilities-container'>
                <div className='possibilities-container__title'>
                    <h2>{t('Возможности системы')} <span>{t('Умная энергия')}</span></h2>
                </div>
                <div className='possibilities-container__content'>
                    <div ref={el => items.current[0] = el} className='possibilities-container__content-item left-slide-in'>
                        <h2>01</h2>
                        <div>
                            <h3>{t('Мгновенная цифровизация всех объектов')}</h3>
                            <p>{t('Система умеет работать с любыми таблицами ' +
                                'и строковыми данными. Просто загрузите текущий ' +
                                'реестр объектов и он появится в системе. ' +
                                'Вы не будете ограничены в формате данных.')}
                            </p>
                        </div>
                    </div>
                    <div ref={el => items.current[1] = el} className='possibilities-container__content-item left-slide-in'>
                        <h2>02</h2>
                        <div>
                            <h3>{t('Исключение риска потери данных')}</h3>
                            <p>{t('Обеспечим защиту и сохранность информации в случае ' +
                                'сбоев в работе системы. Резервное копирование данных может ' +
                                'производиться как автоматическом, так и в ручном режиме.')}
                            </p>
                        </div>
                    </div>
                    <div ref={el => items.current[2] = el} className='possibilities-container__content-item left-slide-in'>
                        <h2>03</h2>
                        <div>
                            <h3>{t('Доступ в личный кабинет')}</h3>
                            <p>{t('Вы можете настраивать и ограничивать доступ к системе для ' +
                                'сотрудников из разных регионов, отделений, подразделений. ' +
                                'Сотрудники будут видеть только нужную им информацию.')}
                            </p>
                        </div>
                    </div>
                    <div ref={el => items.current[3] = el} className='possibilities-container__content-item right-slide-in'>
                        <h2>04</h2>
                        <div>
                            <h3>{t('Техническая поддержка клиентов')}</h3>
                            <p>{t('В системе есть онлайн-чат для связи с менеджером техподдержки. ' +
                                'В чате каждый сотрудник может получить помощь от оператора ' +
                                'по любому возникшему вопросу или проблеме.')}
                            </p>
                        </div>
                    </div>
                    <div ref={el => items.current[4] = el} className='possibilities-container__content-item right-slide-in'>
                        <h2>05</h2>
                        <div>
                            <h3>{t('Снижает время устранения аварий на объектах')}</h3>
                            <p>{t('Быстрый доступ диспетчера к связанным между подстанциями ' +
                                'объектам, актуальному состоянию фидеров и к однолинейной ' +
                                'схеме подстанции и тд.')}
                            </p>
                        </div>
                    </div>
                    <div ref={el => items.current[5] = el} className='possibilities-container__content-item right-slide-in'>
                        <h2>06</h2>
                        <div>
                            <h3>{t('Онлайн-конструктор отрисовки однолинейных схем подстанций')}</h3>
                            <p>{t('Интегрированный в систему редактор блок-схем дает ' +
                                'возможность составить, а также при необходимости схему подстанции.')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Possibilities;
