import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import CustomOffcanvas from "../customOffcanvas/CustomOffcanvas";
import Logo from '../../assets/images/logo.svg';
import RU from '../../assets/images/flags/RU.svg';
import EN from '../../assets/images/flags/EN.svg';
import KZ from '../../assets/images/flags/KZ.svg';
import Menu from '../../assets/images/menu.svg';
import './header.css';
import './@header.css';

function Header() {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null);
    const headerRef = useRef(null);

    const languageFlags = {
        ru: RU,
        en: EN,
        kz: KZ
    };

    const [currentFlag, setCurrentFlag] = useState(languageFlags[currentLanguage]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
        localStorage.setItem("selectedLanguage", lng);
        setCurrentFlag(languageFlags[lng]);
    };

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            changeLanguage(selectedLanguage);
        }

        const handleHeaderClick = (e) => {
            if (headerRef.current && !headerRef.current.contains(e.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("click", handleHeaderClick);
        return () => {
            document.removeEventListener("click", handleHeaderClick);
        };
    }, []);

    const handleClose = () => {
        setShow(false);
    };

    const handleLanguageSelect = (selectedLanguage) => {
        if (selectedLanguage !== currentLanguage) {
            changeLanguage(selectedLanguage);
        }
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleCanvas = () => {
        setShow(!show);
    };

    const availableLanguages = Object.keys(languageFlags);
    const languageList = availableLanguages.filter(lang => lang !== currentLanguage);

    return (
        <div className='header' ref={headerRef}>
            <div className='header-container'>
                <div className='header-container__logo'>
                    <Nav.Link href='/'>
                        <img
                            src={Logo}
                            alt="Логотип"
                        />
                    </Nav.Link>
                </div>
                <div>
                    <div className='header-container__content'>
                        <div
                            className='header-container__content-dropdown'
                            onClick={toggleDropdown}
                            ref={dropdownRef}
                        >
                            <div className='header-container__content-dropdown__current'>
                                {currentLanguage.toUpperCase()}
                                <img src={currentFlag} alt={currentLanguage} />
                            </div>
                            {isDropdownOpen && (
                                <div className='header-container__content-dropdown__content'>
                                    {languageList.map((lang) => (
                                        <div className='header-container__content-dropdown__content-item' key={lang}>
                                            <div
                                                onClick={() => handleLanguageSelect(lang)}
                                            >
                                                {lang.toUpperCase()}
                                                <img src={languageFlags[lang]} alt={lang} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className='header-container__content-link'>
                            <Nav.Link href='#possibilities'>{t('Возможности системы')}</Nav.Link>
                            <Nav.Link href='#clients'>{t('Наши клиенты')}</Nav.Link>
                            <Nav.Link href='#form'><span>{t('Оставить заявку на тестирование')}</span></Nav.Link>
                        </div>
                    </div>
                </div>
                <div className='header-container__area'>
                    <Nav.Link href='http://app.smart-energy.online'><span>{t('Личный кабинет')}</span></Nav.Link>
                </div>
                <div className='menu'>
                    <button onClick={toggleCanvas}><img src={Menu} alt='Меню' /></button>
                </div>
            </div>
            <CustomOffcanvas
                show={show}
                onHide={handleClose}
                currentLanguage={currentLanguage}
                changeLanguage={changeLanguage}
            />
        </div>
    );
}

export default Header;
