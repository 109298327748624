import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import emailjs from '@emailjs/browser';
import InputMask from 'react-input-mask';
import PC from '../../assets/images/pc.svg';
import './callback.css';
import './@callback.css';
import {Modal} from "react-bootstrap";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";

function Callback() {
    const { t } = useTranslation();
    const form = useRef();
    const phoneInput = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    const resetForm = () => {
        form.current.reset();
        phoneInput.current.value = '';
    };

    const validatePhoneNumber = () => {
        const phoneNumber = phoneInput.current.value.replace(/[^0-9]/g, '');
        if (phoneNumber.length !== 11) {
            setErrorMessage(t('Пожалуйста, введите правильный номер телефона'));
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validatePhoneNumber()) {
            return;
        }

        setIsSubmitting(true);

        emailjs.sendForm('service_9x4drsb', 'template_c1dkh9t', form.current, 'K68qq420c7qOWbRiF')
            .then((result) => {
                console.log(result.text);
                setIsSubmitting(false);
                setIsSuccess(true);
                resetForm();

                setShowModal(true);

                setTimeout(function () {
                    setIsSuccess(false);
                }, 1000);
            })
            .catch((error) => {
                console.log(error.text);
                setIsSubmitting(false);
                setIsError(true);
                setTimeout(function () {
                    setIsError(false);
                    resetForm();
                }, 1000);
            });
    };

    const handleAgreementChange = () => {
        setIsAgreementChecked(!isAgreementChecked);
    };

    return (
        <div className='form' id='form'>
            <div className='form-container'>
                <div className='form-container__content'>
                    <div className='form-container__content-title'>
                        <h2>{t('Бесплатное тестирование')}</h2>
                        <p>
                            {t('Вы можете бесплатно и без заключения договора протестировать ' +
                                'работу системы на 10 объектах своей компании.')}
                        </p>
                    </div>
                    <div className='form-container__content-form'>
                        <form ref={form} onSubmit={sendEmail} className='form-container__content-form__form'>
                            <div className='form-container__content-form__form-first'>
                                <div className='form-container__content-form__form-first__name'>
                                    <input
                                        name="form_name"
                                        type="text"
                                        placeholder={t('Ваше имя')}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className='form-container__content-form__form-first__comment'>
                                    <textarea
                                        name="form_comment"
                                        placeholder={t('Комментарий')}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className='form-container__content-form__form-second'>
                                <div className='form-container__content-form__form-second__tel'>
                                    <InputMask
                                        mask="+7 999 999-99-99"
                                        placeholder='+7| (999) 000-00-00'
                                        maskChar=""
                                        type="tel"
                                        name="form_phone"
                                        autoComplete="off"
                                        required
                                        ref={phoneInput}
                                    />
                                </div>
                                <div className='form-container__content-form__form-second__btn'>
                                    <button type="submit" disabled={isSubmitting || isSuccess || isError}>
                                        {isSuccess ? t('Успех!') : isError ? t('Ошибка') : isSubmitting ? t('Отправка...') : t('Оставить заявку')}
                                    </button>
                                </div>
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <div className='form-container__content-form__form-third'>
                                <CustomCheckbox

                                    isChecked={isAgreementChecked}
                                    onChange={handleAgreementChange}
                                />
                            </div>
                        </form>
                        <div className='form-container__content-form__img'>
                            <img src={PC} alt='PC' />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2>{t('Спасибо за вашу заявку!')}</h2>
                    <p>{t('Мы свяжемся с вами в ближайшее время.')}</p>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Callback;
