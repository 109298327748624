import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Privacy from "./components/privacy/Privacy";

import enTranslation from './languages/en.json';
import kzTranslation from './languages/kz.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            kz: {
                translation: kzTranslation,
            },
        },
        lng: 'ru',
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/" element={<App/>} />
              <Route path="/privacy" element={<Privacy/>} />
          </Routes>
      </Router>
  </React.StrictMode>
);

reportWebVitals();
