import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import AboutUs from '../../assets/images/about.svg';
import AboutUsMedia from '../../assets/images/about_media.svg';
import Gift from '../../assets/images/about/gift.png';
import Timer from '../../assets/images/about/timer.png';
import Puzzle from '../../assets/images/about/puzzle.png';
import Files from '../../assets/images/about/files.png';
import Card from '../../assets/images/about/card.png';
import '../../assets/fonts/fonts.css';
import './about.css';
import './@about.css';

function About() {
    const { t } = useTranslation();

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    return (
        <div className='about'>
            <div className='about-container'>
                <div className='about-container__title'>
                    <h1>{t('Цифровая система учета и мониторинга электросетевого хозяйства')}</h1>
                    <p>{t('Соответствует 152-ФЗ от 27.07.2006')}</p>
                </div>
                <div className='about-container__btn'>
                    <a href='#form'>
                        <button>{t('Обратный звонок')}</button>
                    </a>
                </div>
                <div className='about-container__image'>
                    <img src={AboutUs} alt='' />
                </div>
                <div className='about-container__image-media'>
                    <img src={AboutUsMedia} alt='' />
                </div>
                <div className='about-container__content'>
                    <div className='about-container__content-item item1'>
                        <img src={Gift} alt='' />
                        <p>{t('Бесплатное тестовое внедрение на ваших объектах')}</p>
                    </div>
                    <div className='about-container__content-item item2'>
                        <img src={Timer} alt='' />
                        <p>{t('Cнижение времени реагирования на аварии')}</p>
                    </div>
                    <div className='about-container__content-item item3'>
                        <img src={Puzzle} alt='' />
                        <p>{t('Поэтапное внедрение в систему')}</p>
                    </div>
                    <div className='about-container__content-item item4'>
                        <img src={Files} alt='' />
                        <p>{t('Не требует специально обученных специалистов')}</p>
                    </div>
                    <div className='about-container__content-item item5'>
                        <img src={Card} alt='' />
                        <p>{t('Доступная стоимость внедрения и обслуживания')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
