import React, {useEffect} from 'react';
import i18n from "i18next";
import Logo from '../../assets/images/logo-white.svg';
import {Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import './footer.css';
import './@footer.css';

function Footer(){
    const { t } = useTranslation();

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("selectedLanguage");
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    return(
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-container__logo'>
                    <img src={Logo} alt='Logo'/>
                </div>
                <div className='footer-container__test'>
                    <Nav.Link>{t('Оставить заявку на тестирование')}</Nav.Link>
                </div>
                <div className='footer-container__links'>
                    <p>{t('Контакты')}</p>
                    <div>
                        <Nav.Link href='tel:79225094759'>+7 (922) 509-47-59</Nav.Link>
                    </div>
                    <div>
                        <Nav.Link href='mailto:development@smart-energy.online'>development@smart-energy.online</Nav.Link>
                    </div>
                    <div>
                        < Nav.Link target='_blank' href='https://t.me/MobileApps18'>@MobileApps18</Nav.Link>
                    </div>
                </div>
                <div className='footer-container__account'>
                    <Nav.Link href='http://app.smart-energy.online'>{t('Личный кабинет')}</Nav.Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;
